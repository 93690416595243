import React , { useEffect } from "react"
import Hero from "../components/Hero"
import Navbar from "../components/Navbar"
import {Helmet} from "react-helmet";
import smoothscroll from 'smoothscroll-polyfill';
import favIco from '../images/favicon.ico';

export default () => {
  const [navState, setNavState] = React.useState(false)

  useEffect(()=>{
    smoothscroll.polyfill()
  },[])

  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <link rel="icon" type="image/png" href={favIco} sizes="16x16" />
          <title>Octavio Almada</title>
          <link rel="canonical" href="https://octavioalmada.com.ar/" />
        </Helmet>
      <Navbar navState={navState}/>
      <Hero  setNavState={setNavState} />
    </div>
     
 
  
  )
    
}
