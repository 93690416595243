import React, { useEffect, useRef } from "react"
import Jobs from "../components/Jobs"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FaArrowDown } from "react-icons/fa"
import { FaArrowUp } from "react-icons/fa"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Link from "gatsby-link"
import BoltIcon from '@mui/icons-material/Bolt';
import ArticleIcon from '@mui/icons-material/Article';
import MailIcon from '@mui/icons-material/Mail';
import { isMobile } from 'react-device-detect';
import copy from "copy-to-clipboard";  

// ...GatsbyImageSharpFluid



const Hero = (props) => {
    const data = useStaticQuery(graphql`
        query MyQuery {
          allFile(filter: {relativeDirectory: {eq: "images/hero"}}) {
            edges {
              node {
                base
                childImageSharp {
                  fluid {
                    aspectRatio
                    base64
                    src
                    srcSet
                    sizes
                  }
                }
                name
              }
            }
          }
        }


    `
  )
  

        
  const [scroll, setScroll] = React.useState(false)
  const about = useRef(null)
  const hero = useRef(null)
  const contact = useRef(null)
  const setNavState = props.setNavState;
  const scrollDown = () => {
    about.current.scrollIntoView(  {behavior: "smooth", block: "center", inline: "center" });
  }  
  const contactScroll = () => {
    contact.current.scrollIntoView(  {behavior: "smooth", block: "center", inline: "center" });
  }  

  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  
  const setFromEvent = (e) => setPosition({ x: e.clientX, y: ((window.innerHeight/2)-e.clientY)/10 });
  useEffect(() => {
    window.addEventListener("mousemove", setFromEvent);
    return () => {
      window.removeEventListener("mousemove",setFromEvent);
    };
  }, []);


 




  return (
    
    <div  className="landing-hero animate__fadeIn animate__animated">
      <div ref={hero} className='heroContainer'>
        <div className={scroll? "hide-up hero-info" : "hero-info"}>
          <h2>FrontEnd Developer & Designer</h2>
          <span className="h1Wrap">
            <h1>Octavio</h1>
            <h1>Almada</h1>
          </span>
          <p onClick={scrollDown} className="scrollIcon"><FaArrowDown className="animate__infinite  animate__bounce animate__animated"/></p>
        </div>
        <div className="heroImgContainer">
          <div className='heroShape'>
            <div className="subShapeWrapper">
              <span className='subShape1'></span>
              <span className='subShape2'></span>
              <span className='subShape3'></span>
            </div>
            
          </div>
          
          
      
          {data.allFile.edges.map(({node}) => (
            <image id={node.name} className={scroll ? "animate__animated animate__fadeOut": "animate__animated animate__fadeIn"}><Img fluid= {node.childImageSharp.fluid} /></image>
          ))}
        </div>  
      </div>
      
      <div  className="hero-description">
        <div className="noise descriptionNoise"></div>
        <h3  ref={about} >
          Hi! I'm Octavio, a frontend developer and designer with 3 years of experience building interactive and feature-rich websites.
        </h3>
        <h3>
          I take pride in providing both technical and creative knowledge in order to turn ideas into digital experiences.
        </h3>
        <h3>
          Interested in working together?
        </h3>
        <div className="ctaWrapper">
         <a onClick={contactScroll}>Get in touch <ArrowForwardIcon/></a>
         <Link to="/projects/">See My Work <ArrowForwardIcon/></Link>
        </div>

        <div className="skillsContainer">

            <div className="skillsWrapper">
              <h2>Skills & Tools</h2>
              <p>REACT<span><BoltIcon/><BoltIcon/> <BoltIcon/></span>  </p>
              <p>HTML5 / CSS3<span><BoltIcon/> <BoltIcon/> <BoltIcon/> </span></p>
              <p>JAVACRIPT<span><BoltIcon/> <BoltIcon/> <BoltIcon/></span> </p>
              <p>jQUERY<span><BoltIcon/><BoltIcon/> <BoltIcon/> </span> </p>
              <p>GATSBY<span><BoltIcon/><BoltIcon/></span>  </p>
              <p>TAILWIND<span><BoltIcon/><BoltIcon/></span>  </p>
              <p>FIREBASE<span><BoltIcon/><BoltIcon/></span>  </p>
              <p>ELECTRON JS<span><BoltIcon/></span>   </p>
              <p>PYTHON<span><BoltIcon/></span>   </p>
            </div>
            <div className="cvWrapper">
              
              <h2> <ArticleIcon/>Past experience</h2>
              <p>Want to know more about my education and experience? </p>
              <a href="https://drive.google.com/file/d/1ehZvqCupWDmxnoOaRBOcqYU1IYEG7A3x/view?usp=sharing" target='_blank'>My Resume <ArrowForwardIcon/></a>
              
            </div>


        </div>
        <div ref={contact} className="contactContainer">
            <span className='line'></span>
            <div className='contactShape'>
              <h2> Interested?</h2>
              <h2> Lets Get In Touch!</h2>
              <p>I’m open to freelance opportunities or remote positions. </p>
             

              
              {isMobile &&
                
                <a href="mailto:almadaocta@gmail.com?subject=FREELANCE"> <MailIcon/>almadaocta@gmail.com</a>
              }
              {!isMobile && 
                <a onClick={()=>copy('almadaocta@gmail.com')}> <MailIcon/>almadaocta@gmail.com</a>
              }
              <div className="subShapeWrapper">
                <span className='subShape1'></span>
                <span className='subShape2'></span>
                <span className='subShape3'></span>
              </div>
            </div>
            
            

        </div>
        
        
      </div>

    </div>
    
  )



}

export default Hero
